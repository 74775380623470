import { createAction, createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'

import api from '../utils/api'
import { getS3Signature, uploadToS3 } from '../utils/s3'
import { removeUndefined } from '../utils/sanitize'

type IProps = {
  ids: string[]
  idsByBusinessId: Record<string, string[]>
  businessStores: Record<string, any>
  childStores: Record<string, any>
  insights: Record<string, any>
  paging: Record<string, any>
  emptySearchResults: boolean
  productsByBusinessStoreId: Record<string, any>
}

const initialState: IProps = {
  ids: [],
  idsByBusinessId: {},
  businessStores: {},
  productsByBusinessStoreId: {},
  childStores: {},
  insights: {},
  paging: {},
  emptySearchResults: false
}

const slice = createSlice({
  name: 'businessStore',
  initialState: initialState,
  reducers: {
    fetchChildStoresSuccess(state, action) {
      const { storeId, ...rest } = action.payload
      state.childStores[storeId] = rest
    },
    fetchStoreInsightsSuccess(state, action) {
      const { storeId, ...rest } = action.payload
      state.insights[storeId] = rest
    },
    fetchBusinessStoresSuccess(state, action) {
      const { businessId, business_stores, paging, page } = action.payload

      // Clear business stores if the stores do not belong to the business the user is currently on
      if (
        Object.values(state.businessStores).filter(
          (b) => b.business_id !== businessId
        ).length
      ) {
        state.ids = []
        state.businessStores = {}
        state.idsByBusinessId[businessId] = []
      }

      state.paging = paging
      if (page) {
        business_stores.forEach((store) => {
          if (state.ids.indexOf(store.id) === -1) state.ids.push(store.id)
          if (state.idsByBusinessId[businessId].indexOf(store.id) === -1)
            state.idsByBusinessId[businessId].push(store.id)
        })
      } else {
        state.ids = business_stores.map((store) => store.id)
        state.idsByBusinessId[businessId] = state.ids
      }
      business_stores.forEach((store) => {
        state.businessStores[store.id] = store
      })
    },
    fetchBusinessStoreSuccess(state, action) {
      const { businessStore } = action.payload

      if (state.ids.indexOf(businessStore.id) === -1) {
        state.ids = state.ids.concat([businessStore.id])
        if (
          state.idsByBusinessId[businessStore.business_id].indexOf(
            businessStore.id
          ) === -1
        ) {
          state.idsByBusinessId[businessStore.business_id].push(
            businessStore.id
          )
        }
      }
      state.businessStores[businessStore.id] = businessStore
    },
    createBusinessStoreSuccess(state, action) {
      const { businessStore } = action.payload

      state.businessStores[businessStore.id] = businessStore
    },
    updateBusinessStoreSuccess(state, action) {
      const { businessStore } = action.payload

      state.businessStores[businessStore.id] = businessStore
    },
    fetchProductsSuccess(state, action) {
      const { storeId, products, paging, reset } = action.payload
      state.emptySearchResults = false
      if (!state.productsByBusinessStoreId[storeId]) {
        state.productsByBusinessStoreId = {
          ...state.productsByBusinessStoreId,
          [storeId]: {}
        }
      }

      if (state.productsByBusinessStoreId[storeId].products && !reset) {
        state.productsByBusinessStoreId[
          storeId
        ].products = state.productsByBusinessStoreId[storeId].products
          .filter((p) => !products.map((pro) => pro.id).includes(p.id))
          .concat(products)
      } else {
        state.productsByBusinessStoreId[storeId].products = products
      }
      if (typeof paging !== 'undefined') {
        state.productsByBusinessStoreId[storeId].paging = paging
      }
    },
    createStoreProductSuccess(state, action) {
      const { storeId, product } = action.payload
      // The product may have been an update of an existing product
      // so find and replace that product with the new version

      if (!state.productsByBusinessStoreId[storeId]) {
        state.productsByBusinessStoreId = {
          ...state.productsByBusinessStoreId,
          [storeId]: {}
        }
      }

      if (state.productsByBusinessStoreId[storeId].products) {
        state.productsByBusinessStoreId[storeId].products = [product].concat(
          state.productsByBusinessStoreId[storeId].products.filter(
            (p) => p.id !== product.id
          )
        )
      } else {
        state.productsByBusinessStoreId[storeId].products = [product]
      }
    },
    deleteStoreProductsSuccess(state, action) {
      const { storeId, productIds } = action.payload

      state.productsByBusinessStoreId[
        storeId
      ].products = state.productsByBusinessStoreId[storeId].products.filter(
        (p) => !productIds.includes(p.id)
      )
    },
    searchProductsSuccess(state, action) {
      const { storeId, products, paging } = action.payload
      state.emptySearchResults = !products.length
      state.productsByBusinessStoreId[storeId].products = products
      state.productsByBusinessStoreId[storeId].paging = paging
    },
    updateLocalProductSuccess(state, action) {
      const { storeId, product } = action.payload
      const products = state.productsByBusinessStoreId[storeId].products
      state.productsByBusinessStoreId[storeId].products = products?.map(
        (item: globalLib.Product) => {
          if (item.product_id === product.product_id) {
            return {
              ...item,
              video_count: product?.video_count,
              videos: product?.videos
            }
          } else {
            return item
          }
        }
      )
    }
  }
})

export default slice.reducer

export const {
  fetchChildStoresSuccess,
  fetchStoreInsightsSuccess,
  fetchBusinessStoresSuccess,
  fetchBusinessStoreSuccess,
  createBusinessStoreSuccess,
  updateBusinessStoreSuccess,
  fetchProductsSuccess,
  createStoreProductSuccess,
  deleteStoreProductsSuccess,
  searchProductsSuccess,
  updateLocalProductSuccess
} = slice.actions

const fetchChildStoresRequest = createAction(
  'businessStore/fetchChildStoresRequest'
)
const fetchChildStoresFailure = createAction(
  'businessStore/fetchChildStoresFailure'
)

export function fetchChildStores({
  businessId,
  storeId,
  startDate = '',
  endDate = '',
  page = ''
}: {
  businessId: string
  storeId: string
  startDate?: string
  endDate?: string
  page?: string
}) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChildStoresRequest())
      const url = !page
        ? `bus/${businessId}/business_stores/${storeId}/insights/parent_linked_stores${
            startDate && endDate
              ? `?start_date=${startDate}&end_date=${endDate}`
              : ''
          }`
        : page

      const response = await api.get(url)
      const { stores_summary, paging, total_number_of_stores } = response.data

      dispatch(
        fetchChildStoresSuccess({
          totalStores: total_number_of_stores,
          stores: stores_summary,
          storeId,
          paging
        })
      )

      return response
    } catch (error) {
      dispatch(fetchChildStoresFailure())

      return error
    }
  }
}

const fetchStoreInsightsRequest = createAction(
  'businessStore/fetchStoreInsightsRequest'
)
const fetchStoreInsightsFailure = createAction(
  'businessStore/fetchStoreInsightsFailure'
)

export function fetchStoreInsights({
  businessId,
  storeId,
  startDate = '',
  endDate = ''
}: {
  businessId: string
  storeId: string
  startDate?: string
  endDate?: string
}) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchStoreInsightsRequest())
      let params = ''
      if (startDate && endDate)
        params += `start_date=${startDate}&end_date=${endDate}`

      const productsUrl = `bus/${businessId}/business_stores/${storeId}/insights/parent_aggregated_top_selling_products${
        params ? `?${params}` : ''
      }`

      const summaryUrl = `bus/${businessId}/business_stores/${storeId}/insights/parent_aggregated_sales_summary${
        params ? `?${params}` : ''
      }`

      const productRes = await api.get(productsUrl)
      const summaryRes = await api.get(summaryUrl)

      dispatch(
        fetchStoreInsightsSuccess({
          products: productRes.data.top_selling_products,
          summary: summaryRes.data,
          storeId
        })
      )

      return
    } catch (error) {
      dispatch(fetchStoreInsightsFailure())

      return error
    }
  }
}

const fetchBusinessStoresRequest = createAction(
  'businessStore/fetchBusinessStoresRequest'
)
const fetchBusinessStoresFailure = createAction(
  'businessStore/fetchBusinessStoresFailure'
)

export function fetchBusinessStores(
  businessId: string,
  page?: string,
  pageSize = 10,
  keyword?: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchBusinessStoresRequest())
      let url = `bus/${businessId}/business_stores?page_size=${pageSize}`
      if (page) {
        url = page
      }
      const response = await api.get(url, {
        params: {
          q: keyword
        }
      })
      const { business_stores, paging } = response.data
      dispatch(
        fetchBusinessStoresSuccess({
          businessId,
          business_stores,
          paging,
          page
        })
      )

      return response
    } catch (error) {
      dispatch(fetchBusinessStoresFailure())

      return error
    }
  }
}

const fetchBusinessStoreRequest = createAction(
  'businessStore/fetchBusinessStoreRequest'
)
const fetchBusinessStoreFailure = createAction(
  'businessStore/fetchBusinessStoreFailure'
)

export function fetchBusinessStore(
  businessId: string,
  businessStoreId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchBusinessStoreRequest())
      const url = `bus/${businessId}/business_stores/${businessStoreId}`
      const response = await api.get(url)
      const businessStore = response.data
      dispatch(
        fetchBusinessStoreSuccess({
          businessStore
        })
      )

      return response
    } catch (error) {
      dispatch(fetchBusinessStoreFailure())

      return error
    }
  }
}

const createBusinessStoreRequest = createAction(
  'businessStore/createBusinessStoreRequest'
)
const createBusinessStoreFailure = createAction(
  'businessStore/createBusinessStoreFailure'
)

export function createBusinessStore(
  businessId: string,
  data: Record<string, any>
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createBusinessStoreRequest())
      const url = `bus/${businessId}/business_stores`
      const response = await api.post(url, sanitizeBusinessStoreParams(data))
      const businessStore = response.data
      dispatch(createBusinessStoreSuccess({ businessStore }))

      return response
    } catch (error) {
      dispatch(createBusinessStoreFailure())

      return error
    }
  }
}

const updateBusinessStoreRequest = createAction(
  'businessStore/updateBusinessStoreRequest'
)
const updateBusinessStoreFailure = createAction(
  'businessStore/updateBusinessStoreFailure'
)

export function updateBusinessStore(
  businessId: string,
  businessStoreId: string,
  data: Record<string, any>
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateBusinessStoreRequest())
      const url = `bus/${businessId}/business_stores/${businessStoreId}`
      const response = await api.patch(url, sanitizeBusinessStoreParams(data))
      const businessStore = response.data
      dispatch(updateBusinessStoreSuccess({ businessStore }))

      return response
    } catch (error) {
      dispatch(updateBusinessStoreFailure())

      return error
    }
  }
}

const sanitizeBusinessStoreParams = (data) => {
  const sanitized = {
    name: data.name,
    currency: data.currency,
    provider: data.provider,
    url: data.url,
    fast_app_id: data.fast_app_id,
    fast_enabled: data.fast_enabled,
    submitted_shopify_review: data.submitted_shopify_review
  }

  return removeUndefined(sanitized)
}

const deleteBusinessStoreRequest = createAction(
  'businessStore/deleteBusinessStoreRequest'
)
const deleteBusinessStoreFailure = createAction(
  'businessStore/deleteBusinessStoreFailure'
)

export function deleteBusinessStore(
  businessId: string,
  businessStoreId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteBusinessStoreRequest())
      const url = `bus/${businessId}/business_stores/${businessStoreId}`
      const response = await api.delete(url)

      return response
    } catch (error) {
      dispatch(deleteBusinessStoreFailure())

      return error
    }
  }
}

const fetchProductsRequest = createAction('businessStore/fetchProductsRequest')
const fetchProductsFailure = createAction('businessStore/fetchProductsFailure')

export function fetchProducts(params: {
  businessId: string
  storeId: string
  page?: string
  pageSize?: number
  reset?: boolean
  channelId?: string
  accesses?: string
  sort?: string
}) {
  const {
    businessId,
    storeId,
    page,
    pageSize = 20,
    reset,
    channelId,
    accesses = 'public,private',
    sort
  } = params

  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchProductsRequest())
      let url = `bus/${businessId}/products`
      let config = {
        params: {
          page_size: pageSize,
          'vendor_ids[]': storeId,
          channel_id: channelId,
          accesses: channelId ? accesses : undefined,
          sort
        }
      }

      if (page) {
        url = page
        config = undefined
      }

      const response = await api.get(url, config)
      const { products, paging } = response.data
      dispatch(fetchProductsSuccess({ storeId, products, paging, reset }))

      return response
    } catch (error) {
      dispatch(fetchProductsFailure())

      return error
    }
  }
}

const uploadProductCSVRequest = createAction('product/uploadProductCSVRequest')
const uploadProductCSVFailure = createAction('product/uploadProductCSVFailure')

export function uploadProductCSV(
  file: File,
  onProgress: (input: any) => void,
  onSuccess: (input: any) => void
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(uploadProductCSVRequest())
      const signature = await getS3Signature(file)
      await uploadToS3(
        file,
        signature,
        (percent) => onProgress({ percent }),
        ({ key }) => {
          onSuccess({ key })
        },
        (err) => {
          throw err
        }
      )
    } catch (error) {
      dispatch(uploadProductCSVFailure())

      return error
    }
  }
}

const importProductCSVRequest = createAction('product/importProductCSVRequest')
const importProductCSVFailure = createAction('product/importProductCSVFailure')

export function importProductCSV(
  businessId: string,
  businessStoreId: string,
  key: string,
  email: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(importProductCSVRequest())
      const response = await api.post(
        `bus/${businessId}/business_stores/${businessStoreId}/import_csv`,
        { key, email }
      )

      return response
    } catch (error) {
      dispatch(importProductCSVFailure())

      return error
    }
  }
}

const importProductXMLRequest = createAction('product/importProductXMLRequest')
const importProductXMLFailure = createAction('product/importProductXMLFailure')

export function importProductXML(
  businessId: string,
  businessStoreId: string,
  source: string,
  email: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(importProductXMLRequest())
      const payload = { source } as Record<string, string>
      if (email) {
        payload.email = email
      }
      const response = await api.post(
        `bus/${businessId}/business_stores/${businessStoreId}/import_xml/google_merchant`,
        payload
      )

      return response
    } catch (error) {
      dispatch(importProductXMLFailure())

      return error
    }
  }
}

const getImportStatusRequest = createAction('product/getImportStatusRequest')
const getImportStatusFailure = createAction('product/getImportStatusFailure')

export function getImportStatus(businessId: string, businessStoreId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(getImportStatusRequest())
      const response = await api.get(
        `bus/${businessId}/business_stores/${businessStoreId}/import/status`
      )

      return response
    } catch (error) {
      dispatch(getImportStatusFailure())

      return error
    }
  }
}

const searchProductsRequest = createAction(
  'businessStore/searchProductsRequest'
)
const searchProductsFailure = createAction(
  'businessStore/searchProductsFailure'
)

export function searchProducts(params: {
  storeId: string
  businessId: string
  searchTerm: string
  priceFilter: string
  price: string
  sort: string
  channelId?: string
  accesses?: string
  pageSize?: number
}) {
  const {
    storeId,
    businessId,
    searchTerm,
    priceFilter,
    price,
    sort,
    channelId,
    accesses = 'public,private',
    pageSize = 20
  } = params

  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(searchProductsRequest())
      const url = `bus/${businessId}/search_local_products`
      const response = await api.get(url, {
        params: {
          business_store: storeId,
          page_size: pageSize,
          q: searchTerm || undefined,
          ...(sort && {
            sort: sort
          }),
          ...(priceFilter === 'gt'
            ? {
                price_min: price || undefined
              }
            : {
                price_max: price || 0
              }),
          channel_id: channelId,
          accesses: channelId ? accesses : undefined
        }
      })
      const { products, paging } = response.data
      dispatch(searchProductsSuccess({ storeId, products, paging }))
    } catch (error) {
      dispatch(searchProductsFailure())

      return error
    }
  }
}

const importGoogleMerchantRequest = createAction(
  'product/importGoogleMerchantRequest'
)
const importGoogleMerchantFailure = createAction(
  'product/importGoogleMerchantFailure'
)

export function importGoogleMerchant(
  businessId: string,
  businessStoreId: string,
  currency: string,
  email: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(importGoogleMerchantRequest())
      const response = await api.post(
        `bus/${businessId}/business_stores/${businessStoreId}/remote_products_import`,
        { currency, email }
      )

      return response
    } catch (error) {
      dispatch(importGoogleMerchantFailure())

      return error
    }
  }
}

export function updateLocalStoreProduct(
  storeId: string,
  product: globalLib.Product
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    dispatch(
      updateLocalProductSuccess({
        storeId,
        product
      })
    )

    return product
  }
}
