import React from 'react'

import loadable from '@loadable/component'
import { Route, Routes, Navigate } from 'react-router-dom'

import HomePage from '../pages/home'

const BusinessStoreConnect = loadable(() =>
  import('../pages/business-store-connect/Connect')
)

const Pricing = loadable(() => import('../pages/home/Pricing'))

const WPRedirect = loadable(() => import('../pages/home/WPRedirect'))

const UnauthenticatedInvitation = loadable(() =>
  import('../pages/invitation/UnauthenticatedInvitation')
)

const ConsentRequest = loadable(() => import('../pages/ConsentRequest'))

const Integrations = loadable(() => import('../pages/home/Integrations'), {
  resolveComponent: (component) => component.Integrations
})

const Guest = loadable(() => import('../pages/home/Guest'))

export default function UnauthenticatedRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route
        path="/invitation/:token"
        element={<UnauthenticatedInvitation />}
      />
      <Route
        path="/business_store_connect"
        element={<BusinessStoreConnect />}
      />
      <Route path="/wp_callback" element={<WPRedirect />} />
      <Route path="/integration" element={<Integrations />} />
      <Route path="/guest" element={<Guest />} />
      <Route path="/consent_request" element={<ConsentRequest />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}
