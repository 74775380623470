import { localStorageProvider } from '@src/utils/storageProvider'
import Hex from 'crypto-js/enc-hex'
import sha256 from 'crypto-js/sha256'

export const TK_URL = 'https://open.tiktokapis.com/v2'

const TK_PROXY_SERVER_URLS = {
  production: 'https://importer-proxy-firework.vercel.app',
  staging: 'https://importer-proxy-firework-staging.vercel.app'
}

export const TK_CLIENT_KEYS = {
  production: 'aw14lidph09gghga',
  staging: 'aw14lidph09gghga',
  localhost: 'sbawvaljyk4waljbyq'
}

export const TK_PROXY_SERVER_URL =
  TK_PROXY_SERVER_URLS[process.env.REACT_APP_ENV] ||
  (process.env.NODE_ENV === 'production'
    ? TK_PROXY_SERVER_URLS['production']
    : TK_PROXY_SERVER_URLS['staging'])

export const TK_CLIENT_KEY =
  TK_CLIENT_KEYS[process.env.REACT_APP_ENV] ||
  (process.env.NODE_ENV === 'production'
    ? TK_CLIENT_KEYS['production']
    : TK_CLIENT_KEYS['staging'])

export type TKToken = {
  accessToken?: string
  expiresIn?: number
  userInfo?: {
    openId?: string
    unionId?: string
    displayName?: string
    avatarUrl?: string
    username?: string
    likesCount?: number
    videoCount?: number
  }
}
export type TkAuthState =
  | 'video_upload'
  | 'livestream_trailer'
  | 'poster'
  | 'tk_source_importer'

const getTKRedirectUri = (): string => {
  const url = window.location.origin

  if (url.indexOf('localhost') !== -1) {
    return `https://redirectmeto.com/${url}/tiktok/oauth`
  }

  return url + '/tiktok/oauth'
}

function generateRandomString(length: number): string {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

const openTkWindow = (
  state: TkAuthState | string,
  scope = 'user.info.basic,video.list,user.info.profile'
): void => {
  const w = window.screen.width / 3
  const h = window.screen.height / 2
  const l = window.screen.width / 2 - w / 2
  const t = window.screen.height / 2 - h / 2

  //sha 256 and hex encode
  const codeChallenge = Hex.stringify(sha256(generateRandomString(128)))

  const redirectUri = getTKRedirectUri()
  let clientId = TK_CLIENT_KEY
  if (redirectUri.indexOf('localhost') !== -1) {
    clientId = TK_CLIENT_KEYS.localhost
  }

  // business_management
  const url =
    'https://www.tiktok.com/v2/auth/authorize' +
    '?client_key=' +
    clientId +
    '&response_type=code' +
    '&code_challenge=' +
    codeChallenge +
    '&code_challenge_method=S256' +
    '&scope=' +
    scope +
    '&state=' +
    state +
    '&redirect_uri=' +
    encodeURIComponent(redirectUri)

  window.open(
    url,
    'FwTkAuth',
    'resizable,scrollbars,width=' +
      w +
      ',height=' +
      h +
      ',left=' +
      l +
      ',top=' +
      t
  )
}

const saveTKToken = (userId: string, tkToken: TKToken): void => {
  localStorageProvider.setItem(`tk_token_${userId}`, JSON.stringify(tkToken))
}

const getTKToken = (userId: string): TKToken => {
  return JSON.parse(localStorageProvider.getItem(`tk_token_${userId}`) || '{}')
}

const isTKTokenExpired = (tkToken: TKToken): boolean => {
  if (!tkToken || !tkToken.accessToken || !tkToken.expiresIn) {
    return true
  }
  const expiresIn = tkToken.expiresIn
  const date = new Date()

  return expiresIn <= date.getTime() + 10 * 60 * 1000
}

export {
  getTKRedirectUri,
  openTkWindow,
  saveTKToken,
  getTKToken,
  isTKTokenExpired
}
