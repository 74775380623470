import React, { useEffect, useState } from 'react'

import { RightOutlined } from '@ant-design/icons'
import { css } from '@emotion/core'
import { useAppSelector } from '@src/app/hooks'
import { DeleteModal, FWButton } from '@src/components'
import useSpace from '@src/hooks/useSpace'
import {
  beginEditing,
  cancelEditing,
  createInsightAnnotation,
  deleteInsightAnnotation,
  updateInsightAnnotation
} from '@src/redux/insightAnnotations'
import { Collapse, Divider, notification } from 'antd'
import { ErrorMessage, Formik } from 'formik'
import { Form as FormikForm, Input } from 'formik-antd'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import * as Yup from 'yup'

import { FormItem, InputErrorText } from '.'
import theme from '../styles/theme'
import { validateResponseSuccess } from '../utils/api'
import { Box } from './EmotionLayout'

const INITIAL_FORM_VALUES = {
  text: ''
}

const editButtonsStyle = {
  paddingLeft: 10,
  paddingBottom: 0,
  cursor: 'pointer'
}

type MarkedType = {
  marked: boolean
  style?: Record<string, any>
  children: JSX.Element
}

type AnnotationFormType = {
  selectedDate: string
  annotation?: Record<string, string>
  marked?: boolean
}

type AnnotationWidget = {
  selectedDate: string
  annotations: Record<string, any>
}

const Marked: React.FC<MarkedType> = ({
  marked = false,
  style = {},
  children
}: MarkedType) => (
  <div style={{ display: 'flex', ...style }}>
    <div
      style={{
        backgroundColor: marked ? theme.primary : 'transparent',
        width: 5,
        marginRight: 8
      }}
    />
    {children}
  </div>
)

const AnnotationForm: React.FC<AnnotationFormType> = ({
  selectedDate,
  annotation,
  marked = false
}: AnnotationFormType) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { businessId, channelId, videoId } = useParams()
  const showVideoDetails = useAppSelector(
    (state) => state.insight.showVideoDetailsV3[channelId] || null
  )
  const spaceMd = useSpace('medium')

  const WrapMarked = (props) =>
    marked ? (
      <Marked {...props}>{props.children}</Marked>
    ) : (
      <div style={{ display: 'flex', ...props.style }}>{props.children}</div>
    )
  WrapMarked.propTypes = Marked.propTypes

  const getSchema = ({ t }) => {
    const fields = {
      text: Yup.string().trim().max(250, t('Too Long')).required(t('Required'))
    }

    return Yup.object().shape(fields)
  }

  const onSubmitButtonClick = async (values, { setSubmitting }) => {
    const annotation = {
      ...values,
      dashboard_name: 'VIDEO PERFORMANCE',
      report_date: selectedDate,
      video_id: videoId || showVideoDetails.video.video_id
    }

    const res = await dispatch(
      createInsightAnnotation(businessId, channelId, annotation)
    )

    setSubmitting(false)
    if (validateResponseSuccess(res)) {
      notification.success({
        message: t('Annotation Created')
      })
    }
  }

  const onEditSubmitButtonClick = async (values, { setSubmitting }) => {
    if (annotation.text === values.text) {
      dispatch(cancelEditing())
    } else {
      const updatedAnnotation = {
        annotation: {
          ...annotation,
          ...values
        }
      }

      const res = await dispatch(
        updateInsightAnnotation(
          businessId,
          channelId,
          annotation.id,
          updatedAnnotation
        )
      )

      setSubmitting(false)
      if (validateResponseSuccess(res)) {
        notification.success({
          message: t('Annotation Updated')
        })
      }
    }
  }

  return (
    <>
      <WrapMarked>
        <Box
          fontSize="medium"
          fontWeight="bold"
          ml="medium"
          style={{ marginLeft: 0 }}
          color={theme.text}
        >
          {selectedDate}
          {annotation ? (
            ''
          ) : (
            <span
              css={css`
                color: ${theme.primary};
              `}
            >
              &nbsp;&nbsp;*
            </span>
          )}
        </Box>
      </WrapMarked>
      <Formik
        initialValues={
          annotation?.text ? { text: annotation?.text } : INITIAL_FORM_VALUES
        }
        enableReinitialize
        validationSchema={getSchema({ t })}
        onSubmit={annotation ? onEditSubmitButtonClick : onSubmitButtonClick}
      >
        {() => (
          <FormikForm
            layout="vertical"
            labelAlign="left"
            labelCol={{
              xs: { span: 24 }
            }}
            wrapperCol={{
              xs: { span: 24 }
            }}
            colon={false}
            css={css`
              width: 50%;
              .ant-form-item {
                margin-bottom: ${spaceMd};
              }
            `}
          >
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <WrapMarked marked={true}>
                <FormItem style={{ display: 'flex', flex: 1 }}>
                  <Input
                    autoFocus
                    required
                    type="text"
                    name="text"
                    placeholder={t('Enter text')}
                  />
                  <ErrorMessage name="text" render={InputErrorText} />
                </FormItem>
              </WrapMarked>
              <WrapMarked style={{ justifyContent: 'flex-end' }}>
                <>
                  <FWButton onClick={() => dispatch(cancelEditing())}>
                    {t('Cancel')}
                  </FWButton>
                  <FWButton
                    type="primary"
                    htmlType="submit"
                    style={{ marginLeft: '10px' }}
                  >
                    {t('Save')}
                  </FWButton>
                </>
              </WrapMarked>
            </div>
          </FormikForm>
        )}
      </Formik>
    </>
  )
}

const AnnotationItem = ({ annotation }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isEditing = useAppSelector(
    (state) => state.insightAnnotations.isEditing
  )
  const editingDate = useAppSelector(
    (state) => state.insightAnnotations.editingDate
  )

  const { businessId, channelId } = useParams()
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false)

  const handleDelete = async () => {
    const res = await dispatch(
      deleteInsightAnnotation(businessId, channelId, annotation.report_date)
    )

    if (validateResponseSuccess(res)) {
      notification.success({
        message: t('Annotation Deleted')
      })
    }
    setDisplayDeleteModal(false)
  }

  const handleEdit = async () => {
    dispatch(beginEditing(annotation.report_date))
  }

  let itemEl
  if (isEditing && annotation.report_date === editingDate) {
    itemEl = (
      <AnnotationForm
        annotation={annotation}
        selectedDate={annotation.report_date}
      />
    )
  } else {
    itemEl = (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingRight: 10,
            alignItems: 'flex-end'
          }}
        >
          <Box
            fontSize="medium"
            fontWeight="bold"
            ml="medium"
            style={{ marginLeft: 0, color: theme.text }}
          >
            {annotation.report_date}
          </Box>
          <ReactSVG
            height="10"
            src="/edit.svg"
            onClick={() => handleEdit()}
            style={editButtonsStyle}
          />
          <ReactSVG
            as="img"
            height="10"
            src="/trash.svg"
            onClick={() => setDisplayDeleteModal(true)}
            style={editButtonsStyle}
          />
        </div>
        <div>{annotation.text}</div>
      </div>
    )
  }

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      {itemEl}
      {displayDeleteModal && (
        <DeleteModal
          isOpen={displayDeleteModal}
          title={t('Delete Annotation')}
          body={t('Are you sure you want to delete this annotation?')}
          onDelete={handleDelete}
          onCancel={() => setDisplayDeleteModal(false)}
        />
      )}
    </div>
  )
}
AnnotationItem.propTypes = {
  annotation: PropTypes.object
}

const AnnotationList: React.FC<AnnotationWidget> = ({
  annotations,
  selectedDate
}: AnnotationWidget) => {
  const { t } = useTranslation()
  let annotationList
  if (Object.keys(annotations).length > 0) {
    annotationList = (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {Object.values(annotations).map((annotation) => (
          <div key={annotation.id}>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Marked
              key={annotation.report_date}
              marked={annotation.report_date === selectedDate}
            >
              <AnnotationItem
                key={annotation.report_date}
                annotation={annotation}
              />
            </Marked>
          </div>
        ))}
      </div>
    )
  } else if (selectedDate === null) {
    annotationList = (
      <div style={{ paddingTop: '5px', fontWeight: 'bold' }}>
        {t(
          'No annotations. Please select a date on the chart above to start annotating.'
        )}
      </div>
    )
  } else {
    annotationList = null
  }

  return <>{annotationList}</>
}

export default function AnnotationWidget({
  selectedDate,
  annotations = {}
}: AnnotationWidget): JSX.Element {
  const { Panel } = Collapse
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const isCreating = useAppSelector(
    (state) =>
      state.insightAnnotations.isEditing &&
      !(
        state.insightAnnotations.editingDate in
        state.insightAnnotations.annotations
      )
  )

  const [accordionOpen, setAccordionOpen] = useState(true)
  useEffect(() => {
    if (selectedDate !== null) setAccordionOpen(true)
  }, [selectedDate])
  const toggleAccordion = () => setAccordionOpen(!accordionOpen)

  let createEl
  if (!selectedDate || selectedDate in annotations) {
    createEl = null
  } else if (isCreating) {
    createEl = <AnnotationForm marked={true} selectedDate={selectedDate} />
  } else {
    createEl = (
      <div
        style={{
          border: `dashed 1px ${theme.gray15}`,
          borderRadius: '4px',
          backgroundColor: theme.gray17,
          color: '#4B6EA6',
          padding: '30px',
          textAlign: 'center',
          fontWeight: 500,
          width: '50%',
          cursor: 'pointer'
        }}
        onClick={() => dispatch(beginEditing(selectedDate))}
      >
        {t('+ Create Annotation')}
      </div>
    )
  }

  return (
    <Collapse
      css={css`
        .ant-collapse-header {
          display: flex;
          justify-content: center;
          align-items: center;

          .ant-collapse-arrow {
            position: relative !important;
            top: -6px !important;
            left: 135px !important;
            color: #4b6ea6;
          }
        }
      `}
      activeKey={accordionOpen ? 1 : 0}
      onChange={toggleAccordion}
      expandIcon={({ isActive }) => (
        <RightOutlined rotate={isActive ? -90 : 90} />
      )}
      ghost
    >
      <Panel
        header={
          <span style={{ color: '#4B6EA6' }}>{t('View Annotations')}</span>
        }
        key={1}
        style={{ overflow: 'auto', maxHeight: '400px' }}
      >
        {createEl}
        <AnnotationList annotations={annotations} selectedDate={selectedDate} />
      </Panel>
    </Collapse>
  )
}
