import React, { useState } from 'react'

import { StarFilled, StarOutlined } from '@ant-design/icons'
import css from '@emotion/css'
import { FWModal } from '@src/components/FWModal/FWModal'
import { usePixelAmpTracking } from '@src/hooks'
import theme from '@src/styles/theme'
import { TRACKING_EVENTS } from '@src/utils/tracking'
import { Input, Space } from 'antd'
import { useTranslation } from 'react-i18next'

import { FWButton, Title } from '..'
import { Box, Flex } from '../EmotionLayout'
import { ModalCloseIcon } from '../ModalCloseIcon'
import FWLogoBlack from './fw_logo_black.svg'

export type ReviewModalProps = {
  open: boolean
  onClose: () => void
}

export const ReviewModal: React.FC<ReviewModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(open)
  const { pixelAmpTracking } = usePixelAmpTracking()
  const [hoverRating, setHoverRating] = useState(0)
  const [rating, setRating] = useState(0)
  const [feedbackStep, setFeedbackStep] = useState<
    'rating' | 'feedback' | 'thanks'
  >('rating')
  const [feedback, setFeedback] = useState('')

  const updateRatingOnHover = (value: number) => {
    setHoverRating(value)
  }

  const handleRatingClick = (value: number) => {
    setRating(value)
    if (value <= 4) {
      setFeedbackStep('feedback')
    } else {
      pixelAmpTracking(TRACKING_EVENTS.BUSINESS_REDIRECT_TO_SHOPIFY_FEEDBACK)
      onClose?.()
      window.open(
        'https://apps.shopify.com/firework?reveal_new_review=true',
        '_blank'
      )
    }
  }

  const handleSubmitFeedback = () => {
    pixelAmpTracking(TRACKING_EVENTS.BUSINESS_SUBMIT_FEEDBACK, {
      rating,
      feedback: feedback
    })
    setFeedbackStep('thanks')
  }

  const handleClose = () => {
    setShowModal(false)
    onClose?.()
  }

  return (
    <FWModal
      title={null}
      open={showModal}
      onCancel={handleClose}
      footer={null}
      closeIcon={<ModalCloseIcon onClick={handleClose} />}
      css={css`
        .ant-modal-content {
          border-radius: 12px;
          width: 440px;
          min-height: 333px;
          padding: 32px 24px 32px 24px;
        }
        .ant-modal-body {
          padding: 0;
        }
      `}
    >
      <Flex
        width="100%"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
      >
        <Box as="img" src={FWLogoBlack} alt="FW Logo" mb="42" width={144} />
        {feedbackStep === 'rating' && (
          <>
            <Title fontSize={24} weight={600} noMargin>
              {t('How would you rate your Firework experience so far?')}
            </Title>
            <Space
              style={{ marginTop: 31, cursor: 'pointer' }}
              onMouseLeave={() => updateRatingOnHover(0)}
            >
              {Array.from({ length: 5 }).map((_, index) => {
                if ((hoverRating || rating) > index) {
                  return (
                    <StarFilled
                      key={index}
                      style={{ fontSize: '2rem', color: theme.primary }}
                      onMouseEnter={() => updateRatingOnHover(index + 1)}
                      onClick={() => handleRatingClick(index + 1)}
                    />
                  )
                }

                return (
                  <StarOutlined
                    key={index}
                    style={{
                      fontSize: '2rem',
                      color: theme.primary,
                      cursor: 'pointer'
                    }}
                    onMouseEnter={() => updateRatingOnHover(index + 1)}
                  />
                )
              })}
            </Space>
          </>
        )}
        {feedbackStep === 'feedback' && (
          <>
            <Title fontSize={24} weight={600} noMargin>
              {t('How can we get your 5-star rating?')}
            </Title>
            <Box width="100%" mt="22">
              <Input.TextArea
                rows={4}
                style={{ resize: 'none', borderRadius: 4 }}
                onBlur={(e) => setFeedback(e.target.value)}
              />
            </Box>
            <Flex
              justifyContent="center"
              css={css`
                margin-top: 16px;
              `}
            >
              <FWButton type="primary" onClick={handleSubmitFeedback}>
                {t('Submit')}
              </FWButton>
            </Flex>
          </>
        )}
        {feedbackStep === 'thanks' && (
          <Title fontSize={24} weight={600} noMargin>
            {t('Thank you for your feedback!')}
          </Title>
        )}
      </Flex>
    </FWModal>
  )
}
