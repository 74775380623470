import React, { createContext, memo, useContext } from 'react'

import { MenuOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Flex } from '@src/components/EmotionLayout'
import { useDynamicContainerHeight } from '@src/hooks/usePositionChange'

// Define the state type
interface TopNavigationState {
  toggleShow: boolean
  onToggleSidebar?: () => void
}

// Create contexts
export const TopNavigationContext = createContext<
  TopNavigationState | undefined
>(undefined)

const Nav = styled.nav`
  height: 64px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  padding: 0px 8px;
  width: 100%;
`

const SidebarToggle = styled.button`
  background-color: transparent;
  border: 0;
`

export type NavigationProps = {
  navigation: JSX.Element
  children: JSX.Element[] | JSX.Element
}

const NavComponent: React.FC<NavigationProps> = ({ navigation, children }) => {
  const { toggleShow, onToggleSidebar } = useContext(TopNavigationContext)

  const { leftRoomForContainer, refCallback } = useDynamicContainerHeight(0)

  return (
    <Flex
      flexDirection="column"
      id="channel-container"
      ref={refCallback}
      position="relative"
      height={`calc(100vh - ${leftRoomForContainer}px)`}
    >
      {navigation && (
        <Nav className="tour-h1-navigation-step-2">
          {toggleShow && (
            <SidebarToggle
              type="button"
              onClick={onToggleSidebar}
              className="cy-sidebar-toggle"
            >
              <MenuOutlined
                css={css`
                  font-size: 1.1rem;
                `}
              />
            </SidebarToggle>
          )}
          {navigation}
        </Nav>
      )}
      <div style={{ flex: 'auto', minHeight: 0, overflow: 'auto' }}>
        {children}
      </div>
    </Flex>
  )
}

export const TopNavigation: React.FC<NavigationProps> = memo(NavComponent)
