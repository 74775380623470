import {
  createAsyncThunk,
  Slice,
  SliceCaseReducers,
  createSlice
} from '@reduxjs/toolkit'
import { AUTO_EMBED_TABS } from '@src/constants'
import api from '@src/utils/api'

const SLICE_NAME = 'autoEmbed'

type SliceState = {
  selectedPlaylistId: string
  playlistVideos: globalLib.IVideo[]
  playlistLoading: boolean
  isEnableBannerVisible: boolean
  isHelpBannerVisible: boolean
  embedConfig: AutoEmbedConfiguration[]
  integrationsActiveTab: string
  embedStatus: {
    loading: boolean
    status: 'enabled' | 'disabled' | 'error' | null
  }
  selectedStoreIdByBusinessId: {
    [businessId: string]: string
  }
}

const initialState: SliceState = {
  selectedPlaylistId: null,
  playlistVideos: [],
  playlistLoading: false,
  isEnableBannerVisible: true,
  isHelpBannerVisible: false,
  embedConfig: null,
  integrationsActiveTab: AUTO_EMBED_TABS.HOME_PAGE,
  embedStatus: {
    loading: false,
    status: null
  },
  selectedStoreIdByBusinessId: {}
}

export const autoEmbed_fetchPlaylistVideos = createAsyncThunk(
  `${SLICE_NAME}/fetchPlaylistVideos`,
  async (params: {
    playlistId: string
    channelId: string
    businessId: string
  }) => {
    const { data } = await api.get(
      `/bus/${params.businessId}/channels/${params.channelId}/playlists/${params.playlistId}/videos`
    )

    return data.videos
  }
)

export const autoEmbed_upsertEmbedConfig = createAsyncThunk(
  `${SLICE_NAME}/upsertEmbedConfig`,
  async (params: {
    channelId: string
    businessId: string
    embedConfig: AutoEmbedConfiguration
  }) => {
    const { data } = await api.post(
      `/bus/${params.businessId}/channels/${params.channelId}/upsert_embedded_component`,
      params.embedConfig
    )

    return data
  }
)

export const autoEmbed_fetchEmbedConfig = createAsyncThunk(
  `${SLICE_NAME}/fetchEmbedConfig`,
  async (params: { channelId: string; businessId: string }) => {
    const { data } = await api.get(
      `/bus/${params.businessId}/channels/${params.channelId}/auto_embed_config`
    )

    return data
  }
)

export const autoEmbed_fetchEmbedStatus = createAsyncThunk(
  `${SLICE_NAME}/fetchEmbedStatus`,
  async (params: { storeUrl: string }) => {
    const { data } = await api.get(
      `/shopify/embed_status?myshopify_domain=${params.storeUrl}`
    )

    return data
  }
)

const slice: Slice<
  SliceState,
  SliceCaseReducers<SliceState>,
  typeof SLICE_NAME
> = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetPlaylistVideos: (state) => {
      state.playlistVideos = []
    },
    toggleHelpBanner: (state) => {
      state.isEnableBannerVisible = false
      state.isHelpBannerVisible = true
    },
    setAutoEmbedSelectedPlaylistId: (state, action) => {
      state.selectedPlaylistId = action.payload
    },
    clearAutoEmbedPlaylistVideos: (state) => {
      state.playlistVideos = []
    },
    setIntegrationsActiveTab: (state, action) => {
      state.integrationsActiveTab = action.payload
    },
    setSelectedStoreId: (state, action) => {
      state.selectedStoreIdByBusinessId[action.payload.businessId] =
        action.payload.storeId
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(autoEmbed_fetchPlaylistVideos.fulfilled, (state, action) => {
        state.playlistLoading = false
        state.playlistVideos = action.payload
      })
      .addCase(autoEmbed_fetchPlaylistVideos.pending, (state) => {
        state.playlistLoading = true
        state.playlistVideos = []
      })
      .addCase(autoEmbed_upsertEmbedConfig.fulfilled, (state, action) => {
        if (!state.embedConfig) {
          state.embedConfig = [action.payload]

          return
        }

        state.embedConfig = state.embedConfig.map((config) => {
          if (config.id === action.payload.id) {
            return action.payload
          }

          return config
        })
      })
      .addCase(autoEmbed_upsertEmbedConfig.rejected, () => {
        throw new Error('Failed to save embed config')
      })
      .addCase(autoEmbed_fetchEmbedConfig.fulfilled, (state, action) => {
        state.embedConfig = action.payload
      })
      .addCase(autoEmbed_fetchEmbedStatus.fulfilled, (state, action) => {
        state.embedStatus = {
          loading: false,
          status: action.payload.status
        }
      })
      .addCase(autoEmbed_fetchEmbedStatus.pending, (state) => {
        state.embedStatus = {
          loading: true,
          status: null
        }
      })
      .addCase(autoEmbed_fetchEmbedStatus.rejected, (state) => {
        state.embedStatus = {
          loading: false,
          status: 'error'
        }
      })
  }
})

export default slice.reducer

export const {
  resetPlaylistVideos,
  toggleHelpBanner,
  setAutoEmbedSelectedPlaylistId,
  clearAutoEmbedPlaylistVideos,
  setIntegrationsActiveTab,
  setSelectedStoreId
} = slice.actions
