import '@reach/menu-button/styles.css'
import '@reach/tooltip/styles.css'
import React from 'react'

import * as amplitude from '@amplitude/analytics-browser'
import * as Sentry from '@sentry/browser'
import api from '@src/utils/api'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'

import App from './app/App'
import store from './app/store'
import { KEYS } from './config'
import './styles/index.css'
import './styles/theme.css'

if (
  process.env.REACT_APP_ENV === 'production' &&
  process.env.REACT_APP_VERCEL_ENV === 'production'
) {
  // Initializations for production

  // Redirect back to marketing site if not logged in.
  const url = new URL(window.location.href)
  const token = api.getToken()
  if (url.pathname === '/' && !/token/.test(url.hash) && !token) {
    window.location.replace('https://firework.com')
  }

  // Init Sentry
  Sentry.init({
    environment: process.env.REACT_APP_ENV || 'production',
    dsn: KEYS.SENTRY_DSN,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
      'Non-Error promise rejection captured'
    ]
  })

  // Init Firework Prod StatusPage Status Embed
  const statusPageScript = document.createElement('script')
  statusPageScript.src = 'http://fireworkstatus.com/embed/script.js'
  document.body.appendChild(statusPageScript)

  // Init Google Analytics
  const gaScript1 = document.createElement('script')
  gaScript1.async = true
  gaScript1.src = `https://www.googletagmanager.com/gtag/js`
  document.body.appendChild(gaScript1)

  const gaScript2 = document.createElement('script')
  gaScript2.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'AW-396625284');
    gtag('config', 'UA-96586249-10');
    gtag('config', 'UA-96586249-6');
    gtag('config', 'AW-411548285');
  `
  document.body.appendChild(gaScript2)

  // Init Amplitude
  amplitude.init(KEYS.AMPLITUDE_API_KEY_PROD, null, {
    identityStorage: 'localStorage'
  })

  const tagManagerArgs = {
    gtmId: 'GTM-NLJMJTQ'
  }
  TagManager.initialize(tagManagerArgs)
} else {
  // Initializations for staging/dev

  // Init Firework Staging StatusPage Status Embed
  const statusPageScript = document.createElement('script')
  statusPageScript.src = 'https://fireworkstaging.statuspage.io/embed/script.js'
  document.body.appendChild(statusPageScript)

  // Init Amplitude
  amplitude.init(KEYS.AMPLITUDE_API_KEY_STAGING, null, {
    identityStorage: 'localStorage'
  })
}

// Inject moment-duration-format to moment
momentDurationFormatSetup(moment)

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

let stripePromise = null
if (process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
  stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
}

ReactDOM.render(
  <Provider store={store}>
    {stripePromise ? (
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    ) : (
      <App />
    )}
  </Provider>,
  document.getElementById('root')
)
