import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@src/app/store'

export const subscriptionPlanIdByBusinessId = createSelector(
  (state: RootState, businessId: string) =>
    state.subscriptions.subscriptionsByBusinessId[businessId],
  (businessSubscription) => {
    return businessSubscription?.subscription_plan?.plan_id
  }
)

export const getSubscriptionStartDate = createSelector(
  (state: any, businessId: string) =>
    state.subscriptions.subscriptionsByBusinessId[businessId],
  (businessSubscription) => {
    return businessSubscription?.start_at
  }
)

// Ask for review if it's been 3 weeks since the subscription started
export const shouldAskForReview = createSelector(
  (state: any, businessId: string) =>
    getSubscriptionStartDate(state, businessId),
  (starDate) => {
    const threeWeeks = 1000 * 60 * 60 * 24 * 7 * 3

    return Date.now() - new Date(starDate).getTime() > threeWeeks
  }
)

export const isPLGPilotBusiness = createSelector(
  (state: RootState, businessId: string) =>
    state.subscriptions.subscriptionsByBusinessId[businessId],
  (businessSubscription: globalLib.BusinessSubscription) => {
    return businessSubscription?.payment_plan_id === 'plg_pilot'
  }
)

export const isPLGBusiness = createSelector(
  (state: RootState, businessId: string) =>
    state.subscriptions.subscriptionsByBusinessId[businessId],
  (businessSubscription: globalLib.BusinessSubscription) => {
    return businessSubscription?.payment_plan_id.startsWith('plg_')
  }
)

export const createPaymentPlanByBusinessIdSelector = (businessId: string) => (
  state: RootState
): globalLib.BusinessSubscription =>
  state.subscriptions.subscriptionsByBusinessId[businessId]

export const businessViewUsage = createSelector(
  (state: any, businessId: string) =>
    state.subscriptions.subscriptionUsageByBusinessId[businessId],
  (subscriptionUsage: globalLib.SubscriptionUsage) => {
    return {
      percentage: Math.round(
        (subscriptionUsage?.current?.video_views /
          subscriptionUsage?.maximum?.video_views) *
          100
      ),
      current: subscriptionUsage?.current?.video_views
    }
  }
)

export const getSubscriptionFeaturesByFeatureName = createSelector(
  (state: RootState, businessId: string) =>
    state.subscriptions.subscriptionsByBusinessId[businessId],
  (subscriptionFeatures) => {
    return subscriptionFeatures?.feature_checks?.reduce<
      Record<
        globalLib.BusinessSubscriptionFeatureCheck['feature_name'],
        globalLib.BusinessSubscriptionFeatureCheck
      >
    >((acc, feature) => {
      if (feature?.enabled) {
        acc[feature.feature_name] = feature
      }

      return acc
    }, {})
  }
)

export const getShortVideoLimit = createSelector(
  (state: RootState, businessId: string) =>
    getSubscriptionFeaturesByFeatureName(state, businessId),
  (features) => {
    if (features?.short_videos?.enabled) {
      return features?.short_videos?.limit?.max_videos || 999999999
    } else {
      return 0
    }
  }
)

export const getShortVideoSizeLimit = createSelector(
  (state: RootState, businessId: string) =>
    getSubscriptionFeaturesByFeatureName(state, businessId),
  (features) => {
    if (features?.short_videos?.enabled) {
      return features?.short_videos?.limit?.max_video_size_mb || 500
    } else {
      return 0
    }
  }
)

export const getShortVideoDurationLimit = createSelector(
  (state: RootState, businessId: string) =>
    getSubscriptionFeaturesByFeatureName(state, businessId),
  (features) => {
    if (features?.short_videos?.enabled) {
      return features?.short_videos?.limit?.max_video_duration_sec || 5 * 60
    } else {
      return 0
    }
  }
)

export const isViewUsageOver80Under100 = createSelector(
  (state: RootState, businessId: string) =>
    businessViewUsage(state, businessId),
  (usage) => {
    return usage?.percentage >= 80 && usage?.percentage < 100
  }
)

export const isViewUsageOver100 = createSelector(
  (state: RootState, businessId: string) =>
    businessViewUsage(state, businessId),
  (usage) => {
    return usage?.percentage >= 100
  }
)

export const subscriptionMonthlyEndDate = createSelector(
  (state: any, businessId: string) =>
    state.subscriptions.subscriptionUsageByBusinessId[businessId],
  (subscriptionUsage) => {
    return subscriptionUsage?.meta?.billing_dates?.end_date
  }
)
