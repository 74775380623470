import React, { useState, useEffect } from 'react'

import { css } from '@emotion/core'
import { Box } from '@src/components/EmotionLayout'
import { usePixelAmpTracking } from '@src/hooks'
import { useHorizontalTrailer } from '@src/hooks/useHorizontalTrailer'
import { useVideoLimit } from '@src/hooks/useVideoLimit'
import { useVUContext } from '@src/pages/channel/ChannelVideoUpload/components/VUContext'
import theme from '@src/styles/theme'
import { TRACKING_EVENTS } from '@src/utils/tracking'
import { getVideoWH } from '@src/utils/video'
import { notification, Row, Upload, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { MdCloudUpload } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  LIVESTREAM_EVENT_TRAILER,
  VIDEO_UPLOAD_METHOD,
  MIN_VIDEO_DURATION,
  MIN_FILE_SIZE,
  DEFAULT_TRAILER,
  LIVESTREAM_EVENT_DEFAULT_TRAILER_KEYWORD
} from '../constants'
import { uploadChannelVideo, uploadChannelVideoSuccess } from '../redux/channel'

type IProps = {
  children?: React.ReactNode
  method: string
  setVideoUploaded?: (state: boolean) => void
  setVideoUrl?: (val: null | string) => void
  setVideoWH?: (val?: { width: number; height: number }) => void
  video?: globalLib.IVideo
  videoPlayer?: any
  videoUploaded?: boolean
  videoUrl?: string
  thumbnailUrl?: string
  videoOverlay?: React.ReactElement
  isLivestream?: boolean
  aspectRatio?: string
  showVideoID?: boolean
}

const FormVideoUpload: React.FC<IProps> = ({
  method,
  video,
  videoPlayer,
  videoUrl,
  thumbnailUrl,
  setVideoUrl,
  setVideoWH,
  videoUploaded,
  setVideoUploaded,
  children,
  videoOverlay,
  isLivestream = false,
  showVideoID = true,
  aspectRatio
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { Dragger } = Upload
  const { businessId, channelId } = useParams()
  const { maxVideoSize, maxVideoDuration } = useVideoLimit({
    businessId,
    channelId
  })
  const { pixelAmpTracking } = usePixelAmpTracking()
  const [uploading, setUploading] = useState(false)
  const [showFileList, setShowFileList] = useState(true)
  const [uploadFile, setUploadFile] = useState([])
  const [uploadFileWH, setUploadFileWH] = useState<
    { width: number; height: number } | undefined
  >()
  const [videoThumbnail, setVideoThumbnail] = useState(null)
  const { width, height } = useHorizontalTrailer({
    isHorizontal: aspectRatio === '16:9'
  })
  const { actions } = useVUContext()
  const videoElement = videoPlayer.current

  useEffect(() => {
    if (videoElement) {
      videoElement.addEventListener('loadedmetadata', () => {
        const duration = videoElement.duration
        if (duration < MIN_VIDEO_DURATION) {
          notification.warning({
            message: t('Please upload a video longer than {{second}} seconds', {
              second: MIN_VIDEO_DURATION
            }),
            duration: 6
          })
          setVideoUploaded(false)
          setVideoUrl(null)
          setShowFileList(false)
        } else if (duration > maxVideoDuration) {
          notification.warning({
            message: t(
              'Please upload a video no longer than' +
                ' {{maxVideoDuration}} seconds',
              { maxVideoDuration }
            ),
            duration: 6
          })
          setVideoUploaded(false)
          setVideoUrl(null)
          setShowFileList(false)
        }
      })
    }
  }, [
    t,
    videoElement,
    setVideoUploaded,
    setVideoUrl,
    setShowFileList,
    maxVideoDuration
  ])

  const removeUploadedVideo = (event) => {
    setVideoUploaded(false)
    actions.setChanged(false)
    if (isLivestream) {
      setVideoUrl(DEFAULT_TRAILER)
      dispatch(
        uploadChannelVideoSuccess({
          businessId,
          channelId,
          key: LIVESTREAM_EVENT_TRAILER
        })
      )
    } else {
      setVideoUrl(null)
    }
    setShowFileList(false)
    videoPlayer.current.src = null
    if (event.stopPropagation) event.stopPropagation()
  }

  const accept = ['.mp4', '.mov', '.wmv', '.flv', '.avi']
  const uploadProps = {
    name: 'file',
    accept: accept.join(','),
    disabled: uploading,
    multiple: false,
    fileList: uploadFile,
    onRemove: removeUploadedVideo,
    beforeUpload: async (file) => {
      if (setVideoWH) {
        const fileWH = await getVideoWH(file)
        setUploadFileWH(fileWH)
      }

      const fileExtension = `.${file.name.split('.').pop().toLowerCase()}`
      if (!accept.includes(fileExtension)) {
        message
          .error({
            content: t('{{file}} file is in the wrong format', {
              file: file.name
            }),
            className: 'fw-notification'
          })
          .then()

        return false
      }

      if (file.size < MIN_FILE_SIZE || file.size > maxVideoSize * 1_000_000) {
        const tooSmall = file.size < MIN_FILE_SIZE
        const fileSize = tooSmall
          ? `${MIN_FILE_SIZE / 1000}Kb`
          : `${maxVideoSize}MB`

        const errorMsg = tooSmall
          ? t('{{file}} file is smaller than {{fileSize}}', {
              file: file.name,
              fileSize: fileSize
            })
          : t('{{file}} file is larger than {{fileSize}}', {
              file: file.name,
              fileSize: fileSize
            })

        message
          .error({
            content: errorMsg,
            className: 'fw-notification'
          })
          .then()

        return false
      } else {
        setUploading(true)
        setShowFileList(true)
        const URL = window.URL || window.webkitURL
        setVideoThumbnail(URL.createObjectURL(file))
      }
    },
    customRequest: ({ file, onProgress, onSuccess }: any) => {
      dispatch(
        uploadChannelVideo(businessId, channelId, file, onProgress, onSuccess)
      )
    },
    onChange: (info) => {
      const { status } = info.file
      setUploadFile([info.file])
      if (status === 'done') {
        setVideoUploaded(true)
        actions.setChanged(true)
        setUploading(false)
        setVideoUrl(videoThumbnail)
        if (setVideoWH) {
          setVideoWH(uploadFileWH)
        }
        pixelAmpTracking(TRACKING_EVENTS.BUSINESS_UPLOAD_VIDEO_FINISH)
      } else if (status === 'error')
        notification.error({
          message: t('{{file}} file upload failed', { file: info.file.name })
        })
    }
  }

  return (
    <Row
      css={css`
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;
        .ant-upload-list {
          width: 236px;
        }
        .ant-upload-list-item-card-actions {
          opacity: 1;
        }
        .ant-upload-list-item {
          display: ${showFileList ? 'block' : 'none'};
        }
        .ant-progress-bg {
          height: 5px !important;
        }
        .replace-upload .ant-upload {
          padding: 7px 4px;
        }
      `}
    >
      <img
        css={css`
          max-width: ${width}px;
          height: ${height}px;
          background: black;
          position: absolute;
          z-index: 3;
          object-fit: cover;
          display: ${method === VIDEO_UPLOAD_METHOD.LONG_TO_SHORT &&
          thumbnailUrl
            ? 'block'
            : 'none'};
        `}
        alt="Clip thumbnail"
        src={thumbnailUrl}
      />
      <video
        ref={videoPlayer}
        src={
          videoUrl
            ? videoUrl
            : method === VIDEO_UPLOAD_METHOD.EDIT_VIDEO && video
            ? video.download_url
            : ''
        }
        controlsList="nodownload"
        controls
        loop
        css={css`
          width: ${width}px;
          height: ${height}px;
          background: black;
          position: absolute;
          object-fit: cover;
          z-index: 2;
          display: ${videoUploaded || videoUrl ? 'block' : 'none'};
        `}
      />
      {(!videoUploaded ||
        videoUrl?.includes(LIVESTREAM_EVENT_DEFAULT_TRAILER_KEYWORD)) &&
      isLivestream ? (
        <div
          css={css`
            position: absolute;
            width: ${width}px !important;
            height: ${height}px !important;
          `}
        >
          <div
            className="replace-upload"
            css={css`
              position: absolute;
              top: 10px;
              right: 10px;
              z-index: 100;
              width: 40px;
              height: 40px;
              background: grey;
              border-radius: 50%;
              opacity: 0.5;
              display: block;
            `}
          >
            <Upload
              {...uploadProps}
              css={css`
                z-index: 5;
                color: #ffffff;
                text-align: center;
                cursor: pointer;
              `}
              showUploadList={false}
            >
              <MdCloudUpload
                css={css`
                  font-size: 1.75em;
                  color: #ffffff;
                `}
                onClick={() => {
                  pixelAmpTracking(TRACKING_EVENTS.BUSINESS_UPLOAD_VIDEO)
                }}
              />
            </Upload>
          </div>
        </div>
      ) : null}
      <Dragger
        {...uploadProps}
        name="upload"
        css={css`
          width: ${width}px !important;
          height: ${height}px !important;
          background: ${theme.gray17};
        `}
        showUploadList={{ showRemoveIcon: false }}
      >
        {videoUploaded &&
          !videoUrl?.includes(LIVESTREAM_EVENT_DEFAULT_TRAILER_KEYWORD) &&
          isLivestream &&
          method === VIDEO_UPLOAD_METHOD.LOCAL_UPLOAD && (
            <Box
              as="img"
              src="/delete_icon.svg"
              position="absolute"
              right="10"
              top="10"
              zIndex="100"
              width="40"
              height="40"
              bg="grey"
              borderRadius="50%"
              opacity="0.5"
              cursor="pointer"
              onClick={removeUploadedVideo}
              display={videoUploaded ? 'block' : 'none'}
              css={css`
                &:hover {
                  background: black;
                }
              `}
            />
          )}
        {children}
      </Dragger>
      {videoOverlay}
      {method === VIDEO_UPLOAD_METHOD.EDIT_VIDEO &&
      showVideoID &&
      video &&
      video.video_type !== 'live_stream' ? (
        <Box mt="medium" fontSize="small" color="gray3">{`${t('Video ID')}: ${
          video?.encoded_id
        }`}</Box>
      ) : null}
    </Row>
  )
}

export default FormVideoUpload
