import React, { useEffect } from 'react'

import loadable from '@loadable/component'
import usePageViewDataTracking from '@src/hooks/usePageViewDataTracking'
import { useRequestState } from '@src/hooks/useRequestState'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom'

import {
  ONBOARDING_MODES,
  REQUEST_STATUS,
  VIDEO_UPLOAD_METHOD,
  LIVESTREAM_CREATE_METHOD
} from '../constants'

// Lazy load page components using @loadable/component
const BusinessList = loadable(() => import('../pages/business/BusinessList'))
const OnboardBusiness = loadable(() =>
  import('../pages/business/OnboardBusiness')
)
const ConversationHistory = loadable(() =>
  import('../pages/ava/ConversationHistory')
)
const BusinessForm = loadable(() => import('../pages/business/BusinessForm'))
const BusinessAssociate = loadable(() => import('../pages/business/associate'))
const BusinessStoreConnect = loadable(() =>
  import('../pages/business-store-connect/Connect')
)
const BusinessStoreList = loadable(
  () => import('../pages/business-store-connect/BusinessStoreList'),
  {
    resolveComponent: (component) => component.BusinessStoreList
  }
)

const Channel = loadable(() => import('../pages/channel'))
const ChannelList = loadable(() => import('../pages/channel/ChannelList'))
const CreateChannel = loadable(() => import('../pages/channel/ChannelCreate'))
const MyContentCentral = loadable(() =>
  import('../pages/channel/MyContentCentral')
)
const Integrations = loadable(() => import('../pages/home/Integrations'), {
  resolveComponent: (component) => component.Integrations
})
const VideoPerformance = loadable(() =>
  import('../pages/insight/VideoPerformance')
)
const Invitation = loadable(() => import('../pages/Invitation'))
const AssistantMode = loadable(
  () => import('../pages/livestream/AssistantMode/AssistantMode'),
  {
    resolveComponent: (component) => component.AssistantMode
  }
)
const TabletAssistantMode = loadable(
  () => import('../pages/livestream/TabletAssistantMode/TabletAssistantMode'),
  {
    resolveComponent: (component) => component.TabletAssistantMode
  }
)
const CreateLiveStream = loadable(() =>
  import('../pages/livestream/CreateLiveStream')
)
const ViewLiveStream = loadable(() =>
  import('../pages/livestream/ViewLiveStream')
)
const ViewShowroomEvent = loadable(() =>
  import('../pages/showrooms/ViewShowroomEvent')
)

const WebhooksV2 = loadable(() => import('../pages/webhooks'), {
  resolveComponent: (component) => component.WebhooksV2
})
const CreateEndpoint = loadable(
  () => import('../pages/webhooks/CreateEndpoint/CreateEndpoint'),
  {
    resolveComponent: (component) => component.CreateEndpoint
  }
)
const EndPointDetail = loadable(
  () => import('../pages/webhooks/EndPointDetail/EndPointDetail'),
  {
    resolveComponent: (component) => component.EndPointDetail
  }
)
const ListWebhooks = loadable(
  () => import('../pages/webhooks/ListWebhooks/ListWebhooks'),
  {
    resolveComponent: (component) => component.ListWebhooks
  }
)
const Automation = loadable(() => import('../pages/automations'), {
  resolveComponent: (component) => component.Automation
})
const AutomationLogs = loadable(
  () => import('../pages/automations/AutomationLogs'),
  {
    resolveComponent: (component) => component.AutomationLogs
  }
)
const CreateAutomation = loadable(
  () => import('../pages/automations/CreateAutomation'),
  {
    resolveComponent: (component) => component.CreateAutomation
  }
)
const ListAutomations = loadable(
  () => import('../pages/automations/ListAutomations'),
  {
    resolveComponent: (component) => component.ListAutomations
  }
)
const ViewAutomation = loadable(
  () => import('../pages/automations/ViewAutomation'),
  {
    resolveComponent: (component) => component.ViewAutomation
  }
)
const ChannelVideoUpload = loadable(
  () => import('../pages/channel/ChannelVideoUpload'),
  {
    resolveComponent: (component) => component.ChannelVideoUpload
  }
)
const CreateCohort = loadable(
  () => import('../pages/channel/DynamicContentServing/CreateCohort'),
  {
    resolveComponent: (component) => component.CreateCohort
  }
)
const EditCohort = loadable(
  () => import('../pages/channel/DynamicContentServing/EditCohort'),
  {
    resolveComponent: (component) => component.EditCohort
  }
)
const ViewCohort = loadable(
  () => import('../pages/channel/DynamicContentServing/ViewCohort'),
  {
    resolveComponent: (component) => component.ViewCohort
  }
)
const LivestreamInsights = loadable(
  () => import('../pages/social-commerce/LivestreamInsights'),
  {
    resolveComponent: (component) => component.LivestreamInsights
  }
)
const VideoInsights = loadable(
  () => import('../pages/social-commerce/VideoInsights'),
  {
    resolveComponent: (component) => component.VideoInsights
  }
)
const Addons = loadable(() => import('../pages/pricing/Addons'), {
  resolveComponent: (component) => component.Addons
})

const HelpCenter = loadable(() => import('../components/HelpCenter'))
const OAuthApp = loadable(() => import('../pages/app'))
const Showrooms = loadable(() => import('../pages/showrooms'))
const CreateShowroom = loadable(() =>
  import('../pages/showrooms/CreateShowroom')
)
const ShowroomDetails = loadable(() =>
  import('../pages/showrooms/ShowroomDetails')
)
const Applications = loadable(() => import('../pages/Applications'))
const SubscriptionCallbackLanding = loadable(
  () => import('../pages/business/SubscriptionLanding'),
  {
    resolveComponent: (component) => component.SubscriptionCallbackLanding
  }
)
const SubscriptionCancelLanding = loadable(
  () => import('../pages/business/SubscriptionLanding'),
  {
    resolveComponent: (component) => component.SubscriptionCancelLanding
  }
)
const SubscriptionSettingsLanding = loadable(
  () => import('../pages/business/SubscriptionLanding'),
  {
    resolveComponent: (component) => component.SubscriptionSettingsLanding
  }
)

const Embed = loadable(() => import('../pages/embed'), {
  resolveComponent: (component) => component.EmbedPage
})

const WPRedirect = loadable(() => import('../pages/home/WPRedirect'))
const Landing = loadable(() => import('../pages/Landing'), {
  resolveComponent: (component) => component.Landing
})
const NotFound = loadable(() => import('../pages/NotFound'))
const Payment = loadable(() => import('../pages/payment/Payment'))
const PricingPlan = loadable(() => import('../pages/pricing-plan'))
const Profile = loadable(() => import('../pages/Profile'))
const Settings = loadable(() => import('../pages/settings/Settings'))
const Showcase = loadable(() => import('../pages/Showcase'), {
  resolveComponent: (component) => component.Showcase
})
const CreateStore = loadable(() => import('../pages/stores/CreateStore'))
const Store = loadable(() => import('../pages/stores/Store'), {
  resolveComponent: (component) => component.Store
})
const ConsentRequest = loadable(() => import('../pages/ConsentRequest'))

const InteractiveVideo = loadable(
  () => import('../pages/interactive-video/index'),
  {
    resolveComponent: (component) => component.InteractiveVideo
  }
)

const FlowDetails = loadable(
  () => import('../pages/interactive-video/FlowDetails'),
  {
    resolveComponent: (component) => component.FlowDetails
  }
)

const EmbedFlow = loadable(
  () => import('../pages/interactive-video/EmbedFlow'),
  {
    resolveComponent: (component) => component.EmbedFlow
  }
)

const PreviewFlow = loadable(
  () => import('../pages/interactive-video/PreviewFlow'),
  {
    resolveComponent: (component) => component.PreviewFlow
  }
)

const FlowAnalytics = loadable(
  () => import('../pages/interactive-video/FlowAnalytics'),
  {
    resolveComponent: (component) => component.FlowAnalytics
  }
)

const GeneralSearch = loadable(
  () => import('../pages/general-search/GeneralSearch'),
  {
    resolveComponent: (component) => component.GeneralSearch
  }
)

const IgAuth = loadable(() => import('../pages/instagram/IgAuth'))
const FbAuth = loadable(() => import('../pages/facebook/FbAuth'))
const TkAuth = loadable(() => import('../pages/tiktok/TkAuth'))

const SmartButtons = loadable(() => import('../pages/smart-buttons'), {
  resolveComponent: (component) => component.SmartButtons
})

const AutomationPage = loadable(
  () => import('../pages/smart-buttons/Automation'),
  {
    resolveComponent: (component) => component.Automation
  }
)

const SmartButtonsList = loadable(
  () => import('../pages/smart-buttons/SmartButtonsList'),
  {
    resolveComponent: (component) => component.SmartButtonsList
  }
)

const SmartButtonConfigure = loadable(
  () => import('../pages/smart-buttons/SmartButtonConfigure'),
  {
    resolveComponent: (component) => component.SmartButtonConfigure
  }
)

// AVA
const DomainAva = loadable(() => import('../pages/ava/DomainAva'))

const AvaDetail = loadable(() => import('../pages/ava/AvaDetail'))
const AvaMessageAudit = loadable(() => import('../pages/ava/AvaMessageAudit'))
const AvaMessageFeedback = loadable(() =>
  import('../pages/ava/AvaMessageFeedback')
)

const AssistantProfileCreation = loadable(() =>
  import('../pages/ava/AssistantProfileCreation')
)

const AssistantProfileEdit = loadable(() =>
  import('../pages/ava/AssistantProfileEdit')
)

// OTO
const OTO = loadable(() => import('../pages/oto'))
const VisitorsContainer = loadable(() =>
  import('../pages/oto/Feed/components/VisitorsContainer')
)

const OTOInsights = loadable(() => import('../pages/oto/Insights'))
const OTOTeam = loadable(() => import('../pages/oto/Team'))

const OTOSettings = loadable(() => import('../pages/oto/Settings'))
const OTOAppearance = loadable(() => import('../pages/oto/Settings/Appearance'))
const OTOUserSegments = loadable(() =>
  import('../pages/oto/Settings/UserSegments')
)
const OTOFeeds = loadable(() => import('../pages/oto/Settings/Feeds'))
const OTOSnippets = loadable(() => import('../pages/oto/Settings/Snippets'))
const OTOAutomation = loadable(() => import('../pages/oto/Settings/Automation'))

const Paywall = loadable(() => import('../pages/paywall'), {
  resolveComponent: (component) => component.Paywall
})

const BulkUploadContainer = loadable(
  () => import('../components/BulkUpload/BulkUploadContainer'),
  {
    resolveComponent: (component) => component.BulkUploadContainer
  }
)

const SourceImporterContainer = loadable(
  () => import('../components/SourceImporter/SourceImporterContainer'),
  {
    resolveComponent: (component) => component.SourceImporterContainer
  }
)

const Guest = loadable(() => import('../pages/home/Guest'))

export default function Router() {
  const { pathname } = useLocation()
  const { state } = useRequestState('business/fetchBusiness')
  const navigate = useNavigate()
  usePageViewDataTracking()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  /* Route guard to stop access to business and their pages if user has no access */
  useEffect(() => {
    if (state === REQUEST_STATUS.REJECTED) {
      navigate('/')
    }
  }, [navigate, state])

  return (
    <Routes>
      <Route path="/overview" element={<Navigate to="/" />} />
      <Route
        path="/business/:businessId/overview"
        element={<Navigate to="/" />}
      />
      <Route path="/" element={<Landing />} />
      <Route path="/businesses" element={<BusinessList />} />
      <Route path="/business/create" element={<OnboardBusiness />} />
      <Route
        path="/business/:businessId/update"
        element={<BusinessForm mode={ONBOARDING_MODES.UPDATE} />}
      />
      <Route path="/businesses/associate" element={<BusinessAssociate />} />
      <Route
        path="/business_store_connect"
        element={<BusinessStoreConnect />}
      />
      <Route
        path="/business/:businessId/business_store_list"
        element={<BusinessStoreList />}
      />
      {/* OTO */}
      <Route
        path="/business/:businessId/chat_channel/:chatChannelId"
        element={<OTO />}
      >
        <Route path="/" element={<VisitorsContainer />} />

        <Route path="/visitors" element={<VisitorsContainer />}>
          <Route path="/" element={<></>} />
          <Route path="/:visitorId" element={<></>} />
        </Route>

        <Route path="/insights" element={<OTOInsights />} />
        <Route path="/team" element={<OTOTeam />} />

        <Route path="/settings" element={<OTOSettings />}>
          <Route path="/" element={<OTOAppearance />} />
          <Route path="/appearance" element={<OTOAppearance />} />
          <Route path="/user_segments" element={<OTOUserSegments />} />
          <Route path="/feeds" element={<OTOFeeds />} />
          <Route path="/snippets" element={<OTOSnippets />} />
          <Route path="/automation" element={<OTOAutomation />} />
        </Route>
      </Route>
      <Route path="/business/:businessId/channels" element={<ChannelList />} />
      <Route
        path="/business/:businessId/channel/:channelId/video_performance/:videoId"
        element={<VideoPerformance />}
      />
      <Route
        path="/business/:businessId/channel/:channelId"
        element={<Channel />}
      />
      <Route path="/business/:businessId/channel/:channelId/insights">
        <Route path="/:subtab">
          <Channel />
        </Route>
      </Route>
      <Route
        path="/business/:businessId/channel/:channelId/:tab"
        element={<Channel />}
      />
      <Route
        path="/business/:businessId/channel/:channelId/bulk-upload"
        element={<BulkUploadContainer />}
      />
      <Route
        path="/business/:businessId/channel/:channelId/source-importer"
        element={<SourceImporterContainer />}
      />
      <Route
        path="/business/:businessId/channel/:channelId/mycontent"
        element={<MyContentCentral />}
      />
      <Route
        path="/business/:businessId/channel/:channelId/create-cohort"
        element={<CreateCohort />}
      />
      <Route
        path="/business/:businessId/channel/:channelId/cohort/:cohortId"
        element={<ViewCohort />}
      />
      <Route
        path="/business/:businessId/channel/:channelId/cohort/:cohortId/edit"
        element={<EditCohort />}
      />
      <Route
        path="/business/:businessId/channel/:channelId/automation"
        element={<Automation />}
      >
        <Route path="/" element={<ListAutomations />} />
        <Route path="/logs" element={<AutomationLogs />} />
        <Route path="/create" element={<CreateAutomation />} />
        <Route path="/:automationId" element={<ViewAutomation />} />
      </Route>
      <Route
        path="/business/:businessId/store/create"
        element={<CreateStore />}
      />
      <Route path="/business/:businessId/connect_store" element={<Store />} />
      <Route path="/business/:businessId/store/:storeId" element={<Store />} />
      <Route
        path="/business/:businessId/store/:storeId/:storeTab"
        element={<Store />}
      />
      <Route
        path="/business/:businessId/channel/:channelId/livestream/create"
        element={<CreateLiveStream />}
      />
      <Route
        path="/business/:businessId/channel/:channelId/video/:videoId/livestream/create"
        element={
          <CreateLiveStream method={LIVESTREAM_CREATE_METHOD.FROM_VIDEO} />
        }
      />
      <Route
        path="/business/:businessId/channel/:channelId/video/:videoId/livestream/:livestreamId"
        element={<ViewLiveStream />}
      />
      <Route
        path="/business/:businessId/channel/:channelId/video/:videoId/showroom/:livestreamId"
        element={<ViewShowroomEvent />}
      />
      <Route
        path="/business/:businessId/channel/:channelId/video/:videoId/livestream/:livestreamId/edit"
        element={<CreateLiveStream />}
      />
      <Route
        path="/business/:businessId/channel/:channelId/video/:videoId/livestream/:livestreamId/assistant"
        element={<AssistantMode />}
      />
      <Route
        path="/business/:businessId/channel/:channelId/video/:videoId/livestream/:livestreamId/host_dashboard"
        element={<TabletAssistantMode />}
      />
      <Route
        path="/business/:businessId/channel/:channelId/video/create"
        element={
          <ChannelVideoUpload method={VIDEO_UPLOAD_METHOD.LOCAL_UPLOAD} />
        }
      />
      <Route
        path="/business/:businessId/channel/:channelId/video/:videoId"
        element={<ChannelVideoUpload method={VIDEO_UPLOAD_METHOD.EDIT_VIDEO} />}
      />
      <Route
        path="/business/:businessId/channel/:channelId/video/:videoId/edit"
        element={<ChannelVideoUpload method={VIDEO_UPLOAD_METHOD.EDIT_VIDEO} />}
      />
      <Route
        path="/business/:businessId/channel/create"
        element={<CreateChannel />}
      />
      <Route
        path="/business/:businessId/app/:oauthAppId"
        element={<OAuthApp />}
      />
      <Route
        path="/business/:businessId/app/:oauthAppId/:tab"
        element={<OAuthApp />}
      />
      <Route
        path="/business/:businessId/subscription/callback"
        element={<SubscriptionCallbackLanding />}
      />
      <Route
        path="/business/:businessId/subscription/canceled"
        element={<SubscriptionCancelLanding />}
      />
      <Route
        path="/business/:businessId/subscription/settings"
        element={<SubscriptionSettingsLanding />}
      />
      <Route path="/packages" element={<Paywall />} />
      <Route
        path="/business/:businessId/subscription/packages/:packageId/addons"
        element={<Addons />}
      />
      <Route
        path="/business/:businessId/video_showcase"
        element={<Showcase />}
      />
      <Route path="business/:businessId/apps" element={<Applications />} />
      <Route path="/business/:businessId/payment" element={<Payment />} />
      <Route
        path="/business/:businessId/pricing-plan"
        element={<PricingPlan />}
      />
      <Route path="/business/:businessId/settings" element={<Settings />} />
      <Route
        path="/business/:businessId/settings/:tab"
        element={<Settings />}
      />
      <Route path="/business/:businessId/showrooms" element={<Showrooms />} />
      <Route
        path="/business/:businessId/showrooms/create"
        element={<CreateShowroom />}
      />
      <Route
        path="/business/:businessId/showrooms/:showroomId/edit"
        element={<CreateShowroom />}
      />
      <Route
        path="/business/:businessId/showrooms/:showroomId"
        element={<ShowroomDetails />}
      />
      <Route
        path="/business/:businessId/showrooms/:showroomId/:tab"
        element={<ShowroomDetails />}
      />
      <Route path="/business/:businessId/search" element={<GeneralSearch />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/invitation/:token" element={<Invitation />} />
      <Route path="/wp_callback" element={<WPRedirect />} />
      <Route path="/advertiser" element={<Navigate to="/" />} />
      <Route path="/creator" element={<Navigate to="/" />} />
      <Route path="/publisher" element={<Navigate to="/" />} />
      <Route path="/pricing" element={<Navigate to="/" />} />
      <Route path="/integration" element={<Integrations />} />
      <Route path="/layout_studio" element={<Navigate to="/" />} />
      <Route
        path="/app/business/:businessId/channel/:channelId/video/:videoId/insights"
        element={<VideoInsights />}
      />
      <Route
        path="/app/business/:businessId/channel/:channelId/video/:videoId/livestream/:livestreamId/insights"
        element={<LivestreamInsights />}
      />
      <Route path="business/:businessId/helpcenter" element={<HelpCenter />} />
      <Route path="/business/:businessId/webhooksv2" element={<WebhooksV2 />}>
        <Route path="/" element={<ListWebhooks />} />
        <Route path="/create" element={<CreateEndpoint />} />
        <Route path="/:webhookId" element={<EndPointDetail />} />
      </Route>
      <Route
        path="/business/:businessId/channel/:channelId/flows/:flowId"
        element={<InteractiveVideo />}
      >
        <Route path="/" element={<FlowDetails />} />
        <Route path="/embed" element={<EmbedFlow />} />
        <Route path="/preview" element={<PreviewFlow />} />
        <Route path="/analytics" element={<FlowAnalytics />} />
      </Route>
      <Route path="/instagram/auth" element={<IgAuth />} />
      <Route path="/facebook/auth" element={<FbAuth />} />
      <Route path="/tiktok/oauth" element={<TkAuth />} />
      <Route
        path="/business/:businessId/automations"
        element={<AutomationPage />}
      >
        <Route path="/" element={<Navigate to="./smart-buttons" />} />
        <Route path="/smart-buttons" element={<SmartButtons />}>
          <Route path="/" element={<SmartButtonsList />} />
          <Route path="/create" element={<SmartButtonConfigure />} />
          <Route path="/:buttonId" element={<SmartButtonConfigure />} />
        </Route>
      </Route>
      <Route path="/business/:businessId/ava" element={<DomainAva />} />
      <Route
        path="/business/:businessId/ava/:domainAssistantId"
        element={<AvaDetail />}
      />
      <Route path="/embed" element={<Embed />} />
      <Route
        path="/business/:businessId/ava/assistant_profile/create"
        element={<AssistantProfileCreation />}
      />
      <Route
        path="/business/:businessId/ava/:domainAssistantId/conversations"
        element={<ConversationHistory />}
      />
      <Route
        path="/business/:businessId/ava/:domainAssistantId/audit"
        element={<AvaMessageAudit />}
      />
      <Route
        path="/business/:businessId/ava/:domainAssistantId/message_feedback"
        element={<AvaMessageFeedback />}
      />
      <Route
        path="/business/:businessId/ava/assistant_profile/:assistantId/edit"
        element={<AssistantProfileEdit />}
      />
      <Route path="/guest" element={<Guest />} />
      <Route path="/consent_request" element={<ConsentRequest />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
