import { createAction, createSlice } from '@reduxjs/toolkit'
import {
  getIgAccountList,
  getIgCrawlerHashtagList,
  getIgHashtagList,
  getIgUsernameList,
  IgHashtag,
  IPublicPage
} from '@src/components/IgHashtagImporter/igHashtagImporterModel'
import {
  getTiktokHashtagList,
  getTiktokUsernameList
} from '@src/components/SourceImporter/Tiktok/TiktokImporterModel'
import { FbToken, getFbToken } from '@src/utils/fbAuth'
import { getTKToken, TKToken } from '@src/utils/tkAuth'
import { Dispatch } from 'redux'

type IProps = {
  fbToken: FbToken
  igAccountList: IPublicPage[]
  igUsernameList: string[]
  igHashtagList: IgHashtag[]
  igCrawlerHashtagList: string[]
  tiktokHashtagList: string[]
  tiktokUsernameList: string[]
  tkToken: TKToken
}

const initialState: IProps = {
  fbToken: undefined,
  igAccountList: [],
  igUsernameList: [],
  igHashtagList: [],
  igCrawlerHashtagList: [],
  tiktokHashtagList: [],
  tiktokUsernameList: [],
  tkToken: undefined
}

const slice = createSlice({
  name: 'sourceImporter',
  initialState: initialState,
  reducers: {
    fetchLocalSourceImporterDataSuccess(state, action) {
      const {
        fbToken,
        igAccountList,
        igUsernameList,
        igHashtagList,
        igCrawlerHashtagList,
        tiktokHashtagList,
        tiktokUsernameList,
        tkToken
      } = action.payload
      state.fbToken = fbToken
      state.igAccountList = igAccountList
      state.igUsernameList = igUsernameList
      state.igHashtagList = igHashtagList
      state.igCrawlerHashtagList = igCrawlerHashtagList
      state.tiktokHashtagList = tiktokHashtagList
      state.tiktokUsernameList = tiktokUsernameList
      state.tkToken = tkToken
    },
    clearLocalInstagramImporterDataSuccess(state) {
      state.fbToken = undefined
      state.igAccountList = []
      state.igUsernameList = []
      state.igHashtagList = []
    },
    clearLocalInstagramAccountImporterDataSuccess(state) {
      state.fbToken = undefined
    },
    clearLocalTiktokAccountImporterDataSuccess(state) {
      state.tkToken = undefined
    }
  }
})

export default slice.reducer

export const {
  fetchLocalSourceImporterDataSuccess,
  clearLocalInstagramImporterDataSuccess,
  clearLocalInstagramAccountImporterDataSuccess,
  clearLocalTiktokAccountImporterDataSuccess
} = slice.actions

const fetchLocalSourceImporterDataRequest = createAction(
  'sourceImporter/fetchLocalSourceImporterDataRequest'
)
const fetchLocalSourceImporterDataFailure = createAction(
  'sourceImporter/fetchLocalSourceImporterDataFailure'
)

export function fetchLocalSourceImporterData(userId: string) {
  return async (dispatch: Dispatch): Promise<IProps> => {
    try {
      dispatch(fetchLocalSourceImporterDataRequest())
      const fbToken = getFbToken(userId)
      const igAccountList = getIgAccountList(userId)
      const igUsernameList = getIgUsernameList(userId)
      const igHashtagList = getIgHashtagList(userId)
      const igCrawlerHashtagList = getIgCrawlerHashtagList(userId)

      const tiktokHashtagList = getTiktokHashtagList(userId)
      const tiktokUsernameList = getTiktokUsernameList(userId)
      const tkToken = getTKToken(userId)

      dispatch(
        fetchLocalSourceImporterDataSuccess({
          fbToken,
          igAccountList,
          igUsernameList,
          igHashtagList,
          igCrawlerHashtagList,
          tiktokHashtagList,
          tiktokUsernameList,
          tkToken
        })
      )

      return {
        fbToken,
        igAccountList,
        igUsernameList,
        igHashtagList,
        igCrawlerHashtagList,
        tiktokHashtagList,
        tiktokUsernameList,
        tkToken
      }
    } catch (error) {
      dispatch(fetchLocalSourceImporterDataFailure())

      return null
    }
  }
}
