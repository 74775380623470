import { useCallback, useState, useRef } from 'react'

export const usePositionChange = (
  onChange: (element: HTMLBaseElement | HTMLDivElement) => void
) => {
  const observerRef = useRef<MutationObserver | null>(null)

  return useCallback(
    (element: HTMLBaseElement | HTMLDivElement) => {
      if (element) {
        onChange(element)

        if (observerRef.current) {
          observerRef.current.disconnect()
        }

        observerRef.current = new MutationObserver(() => {
          onChange(element)
        })

        observerRef.current.observe(document.body, {
          childList: true,
          subtree: true,
          attributes: true
        })

        // Clean up the observer on component unmount
        return () => {
          if (observerRef.current) {
            observerRef.current.disconnect()
          }
        }
      }
    },
    [onChange]
  )
}

export const useDynamicContainerHeight = (extraSpace: number) => {
  const [leftRoomForContainer, setLeftRoomForContainer] = useState(260)
  const [height, setHeight] = useState(0)

  const updateTopPosition = useCallback(
    (element: HTMLBaseElement | HTMLDivElement) => {
      if (element) {
        const rect = element.getBoundingClientRect()
        setLeftRoomForContainer(rect.top + extraSpace)
        setHeight(rect.height)
      }
    },
    [extraSpace]
  )

  const refCallback = usePositionChange(updateTopPosition)

  return { leftRoomForContainer, refCallback, height }
}
